<template>
  <div id="banner">
    <!-- 导航1 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>地址管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容2 -->
    <div class="content">
      <!-- 搜索框sel2.1 -->
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
        @submit.native.prevent="getList()"
      >
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <el-select
            v-model="query.parentID"
            placeholder="请选择地区"
            clearable
            filterable
            @change="selClick"
          >
            <el-option
              v-for="item in arealist"
              :key="item.areaID"
              :value="item.areaID"
              :label="item.areaName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selClick()">查询</el-button>
          <!-- <el-button type="success" @click="addClick()">新增</el-button> -->
        </el-form-item>
      </el-form>
      <!-- 列表框2.2 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>地址列表</span>
        </div>
        <!-- <el-tabs v-model="query.type" @tab-click="getList">
          <el-tab-pane
            v-for="item in typeSelList"
            :label="item.name"
            :key="item.id"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs> -->
        <!-- 列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          @sort-change="sortChange"
          @filter-change="filterTag"
        >
          <el-table-column prop="code" label="地址编号"></el-table-column>
          <el-table-column prop="areaName" label="地址名称"></el-table-column>
          <el-table-column prop="shortName" label="地址简称"></el-table-column>
          <el-table-column prop="sortNumber" label="排序"></el-table-column>
          <el-table-column prop="addTime" label="添加时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页条page -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50, 100]"
            layout="total,prev,sizes,pager,next,jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
    <!-- 操作对话框3 -->
    <el-dialog
      :title="form.areaID > 0 ? '编辑地址' : '添加地址'"
      :visible.sync="dialogVisible"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm(form)"
      >
        <!-- 内容3.1 -->
        <el-form-item label="编号" prop="code">
          {{ form.code }}
          <!-- <el-input v-model="form.title" placeholder="请输入标题"></el-input> -->
        </el-form-item>
        <el-form-item label="名称" prop="title">
          {{ form.areaName }}
          <!-- <el-input v-model="form.title" placeholder="请输入标题"></el-input> -->
        </el-form-item>
        <!-- 图片框 -->
        <el-form-item label="简称" prop="linkUrl">
          {{ form.shortName }}
          <!-- <el-input v-model="form.linkUrl" placeholder="请输入链接"></el-input> -->
        </el-form-item>
        <el-form-item
          label="排序"
          prop="sortNumber"
          :rules="[{ type: 'number', message: '请填写数字' }]"
        >
          <el-input
            v-model.number="form.sortNumber"
            placeholder="请输入排序字段"
          ></el-input>
        </el-form-item>

        <!-- 布尔选择框 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="是否选择">
              <el-switch v-model="form.isSelected"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";

export default {
  data() {
    return {
      domain: url.getDomain(),
      query: {},
      form: {},
      tableData: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      submiting: false,
      currentPage4: 1,
      pageSize: 10,
      arealist: [], //地区列表
    };
  },
  created() {
    // 地区
    this.getAreaParent();
    // 列表
    this.getList();
  },
  methods: {
    handleSizeChange: function (val) {
      this.pageSize = val;
      this.currentPage41;
      this.getList();
    },
    handleCurrentChange: function (val) {
      this.currentPage4 = val;
      this.getList();
    },
    // 排序
    sortChange: function (sortColumn) {
      this.query.sortNumber = sortColumn.sort;
      this.query.order = sortColumn.order;
      this.getList();
    },
    // 筛选
    filterTag: function (filter) {
      var val = filter["isEnable"][0];
      this.getList();
    },
    //查询
    selClick() {
      this.currentPage4 = 1;
      this.getList();
    },
    // 新增
    addClick() {
      this.dialogVisible = true;
      this.form = {
        isEnable: true,
      };
    },
    // 获取地区
    getAreaParent() {
      var _this = this;
      var link = url.getSchool("GetAreaParent");
      $.get(link, {}, (data) => {
        _this.arealist = data;
      });
    },
    //列表方法
    getList() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageSize = _this.pageSize;
      params.pageIndex = _this.currentPage4;
      var link = url.getSchool("GetAreaList");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    // 修改
    editClick(row) {
      //     console.info(row)
      //   this.form = Object.assign({}, row);
      this.dialogVisible = true;
      var link = url.getSchool("GetModel_Area");
      var areaid = row.areaID;
      $.get(link, { areaid: areaid }, (res) => {
        this.form = res;
      });
    },
    // 提交
    submitForm(formName) {
      var _this = this;
      var link = url.getSchool("Save_Area");
      var info = this.form;
      console.info(info);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({ type: "success", message: res.msg });
            } else {
              _this.$message({ type: "error", message: res.msg });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>